import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { FaArrowRight } from "react-icons/fa";
import example from "../../assets/image/jpeg/examples.jpg";
 
// Demo styles, see 'Styles' section below for some notes on use.

import { device, breakpoints } from "../../utils";
import Newarrow from '../../assets/image/jpeg/enter.svg';




const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;






const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;



const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 1em;
background-color: rgb(171, 142, 102);

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;









const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;


const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;
font-family: halyard-display, sans-serif;

width: 90%;
color: rgba(255, 255, 255, 0.7);


color: #fff;
font-size: 1.6em;
line-height: 1.5;


@media screen and (max-width: 767px)
{
     margin-bottom: 24px;
     font-size: 22px;
     text-align:center;
     width: 100%;
 }

`;
const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const Buttonnew = styled.button `
color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efe8de;
  border: none;
  padding: 1rem 4rem;
  font-weight: 600;
  font-family: termina, sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  position: relative;
  border-radius: 100px;
  z-index: 1;
  margin-top:20px;
  margin-right: 1rem;
  cursor: pointer;
  outline: none;
  transform-style: preserve-3d;
  transition: 0.2s ease-out;

  &:hover {
    &.main span {
      color: #fff;
      mix-blend-mode: difference;
    }
    &.alt span {
      mix-blend-mode: difference;
      color: white;
    }
  }

  &.main {
    background: #00afc9;
    color: #efe8de;
    margin: 0 auto;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-: -5px -6px 10px #efe8de;
    filter: brightness(1.2);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-: 5px 10px 15px #efe8de;
    filter: brightness(0.8);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &:active {
    &.alt {
      &::after {
        box-: inset 4px 4px 8px #efe8de;
      }

      &::before {
        box-: inset -2px -2px 8px #efe8de;
      }
    }
    &.main {
      &::after {
        box-: 2px 5px 6px #efe8de;
      }

      &::before {
        box-: inset -2px -2px 8px #222;
      }
    }
  }
`

export default function Sunfaq({setIsModalOpen}) {
    return (
      <Section py="4" id="faq" bg="#f7f7fb" className="pb-md-5 mb pt-md-5">
      <Container className="pb-md-5 mb pt-md-5">
        <Row className="justify-content-center">
          <Col lg="12">

            <Iwrap>


<Itext as="h2">Questions about Sunekos</Itext>
</Iwrap>
      <SecondText>
      Frequently Asked Questions

      </SecondText>

          </Col>
        </Row>


        <Row className="">




        <Col

     >
           <Accordion allowZeroExpanded >
<AccordionItem>
    <AccordionItemHeading>
         <AccordionItemButton className="text-[14px] pl-2">
        What is Sunekos London?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Award winner of Safety in Beauty Innovation and Pioneering award 2019, Sunekos combines the unique combination of hyaluronic acid and amino acids which directly stimulates the Extra Cellular Matrix (ECM) fibroblasts triggering dermal biogenesis. <br /> <br />

Sunekos is a non-surgical, injectable treatment used to treat around the eyes, face, neck, decollate and hands. Hyaluronic acid together with amino acid can help you achieve plumper, smoother, firmer and more youthful looking skin


        </p>
    </AccordionItemPanel>
</AccordionItem>




</Accordion>    <br />



           <Accordion allowZeroExpanded >
<AccordionItem>
    <AccordionItemHeading>
         <AccordionItemButton className="text-[14px] pl-2">
        What happens on the day?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Session begins with a thorough tailored consultation which consists of an assessment of your area of concern, medical status and expectations of the treatment. This will help us determine if you are a suitable candidate for the treatment. A tailored treatment plan is then devised according to the assessment along with what to expect during and after the treatment. <br /> < gr/ >

Procedure follows once you are deemed suitable for the treatment. Anaesthetic cream can be applied if needed/upon request. <br /> <br />

Treatment takes place slowly and gradually, taking no longer than 15-20 minutes. Post treatment advice will be addressed and a copy will be sent directly to your email



        </p>
    </AccordionItemPanel>
</AccordionItem>




</Accordion>

<br />



<Accordion allowZeroExpanded >

<AccordionItem>
    <AccordionItemHeading>
         <AccordionItemButton className="text-[14px] pl-2">
        How long do Sunekos results last?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Results can last for up to 6 months before repeating the procedure. This is dependent on the patient and their skin conditions, lifestyle, including metabolic rate, age etc. <br /> <br />

You should see some effects immediately, although the final results will become clear once a full course of treatment is provided.

        </p>
    </AccordionItemPanel>
</AccordionItem>



</Accordion>


<br />



<Accordion allowZeroExpanded >

<AccordionItem>
    <AccordionItemHeading>
         <AccordionItemButton className="text-[14px] pl-2">
        What happens on the day and how long does the Sunekos treatment take?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Depending on the area treated, each session can be completed in 15-30 minutes, including consultation time. All appointments begin with a consultation which is provided with every booking. <br /> <br />

The treatment is carried out as follows: <br />
•	 After disinfecting the area to be treated, areas of concerns will be marked and the product will be gradually injected <br />
•	After the product has been injected, we will perform a gentle massage to shape the treated area.

        </p>
    </AccordionItemPanel>
</AccordionItem>



</Accordion>

<br />

<Accordion allowZeroExpanded >
        <AccordionItem>
    <AccordionItemHeading>
         <AccordionItemButton className="text-[14px] pl-2">
        Is Sunekos safe?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Yes, as Sunekos uses a patented formula with a good safety profile. It is very rare to get serious complications from the treatment. Sunekos acts against the process of ageing in a natural way respecting your natural face contour.<br /> <br />

At Dermamina London, you will only be treated by a professional with extensive experience and expertise in performing treatments and the knowledge and skills to handle any possible side effects or complications.


      </p>
    </AccordionItemPanel>
</AccordionItem>





</Accordion>
<br />
<Accordion allowZeroExpanded >

<AccordionItem>
    <AccordionItemHeading>
         <AccordionItemButton className="text-[14px] pl-2">
        Is Sunekos treatment painful?  </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Patients usually find the level of discomfort to be very mild and manageable. The most typical feedback we get is that the skin feels heated just after the injections. We understand that everyone's pain tolerance is different, and we are happy to provide anaesthetic cream to our patients before the treatment begins.

        </p>
    </AccordionItemPanel>
</AccordionItem>




</Accordion>
<br />




<Accordion allowZeroExpanded >

          <AccordionItem>
    <AccordionItemHeading>
         <AccordionItemButton className="text-[14px] pl-2">
        Is there recovery tine needed after Sunekos?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        No downtime and the patient can immediately continue most activities/life as normal.
Full aftercare is provided prior and post treatment.


        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>


          </Col>



        </Row>



<br /> <br />
        <div onClick={() => setIsModalOpen(true)}
             activeClassName="active"
        >
          <Bookbutton>
            Book Appointment Online
          </Bookbutton>
        </div>


      </Container>
      </Section>


);
}