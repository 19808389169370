import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import {
  Title,
  Button,
  Section,
  Box,
  Text,
  Input,
  Span,
} from "../../components/Core";

import { device } from "../../utils";

import cta from "../../assets/image/png/shape.png";
import svgCurve from "../../assets/image/svg/l1-curve-cta.svg";
import imgL2HeroBg from "../../assets/image/jpeg/dd1.jpg";
import imgL3HeroBg from "../../assets/image/jpeg/masseter-banner.jpg";



const Buttonnew = styled.button `
color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efe8de;
  border: none;
  padding: 1rem 4rem;
  font-weight: 600;
  font-family: termina, sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  position: relative;
  border-radius: 100px;
  z-index: 1;
  margin-top:20px;
  margin-right: 1rem;
  cursor: pointer;
  outline: none;
  transform-style: preserve-3d;
  transition: 0.2s ease-out;

  &:hover {
    &.main span {
      color: #fff;
      mix-blend-mode: difference;
    }
    &.alt span {
      mix-blend-mode: difference;
      color: white;
    }
  }

  &.main {
    background: #00afc9;
    color: #efe8de;
    margin: 0 auto;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;

    filter: brightness(1.2);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
 
    filter: brightness(0.8);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &:active {
    &.alt {
      &::after {
        box-shadow: inset 4px 4px 8px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #efe8de;
      }
    }
    &.main {
      &::after {
        box-shadow: 2px 5px 6px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #222;
      }
    }
  }
`
const SectionNew = styled.div`
  background: url(${imgL3HeroBg}) no-repeat;
  background-size: cover;
  background-position: top;
  padding-top:20px;
  padding-bottom:20px;
  @media ${device.lg} {
    background: url(${imgL3HeroBg}) no-repeat;

    background-position: center right;
  }
`;

const LeftCard = styled(Box)`
  position: absolute;
  top: 0;
  left: 0px;
`;

const RightCard = styled(Box)`
  position: absolute;
  top: 0;
  right: -275px;
`;


const Over = styled(Box)`
background: #111111;
border-radius:5px;
opacity: 0.8;
z-index:-1;
padding:30px;
`;

const NonCTA = ({setIsModalOpen}) => (
  <>
    {/* <!-- CTA section --> */}
   
    <SectionNew className="position-relative" id="book">
  
  
      <Container>
        
        <Row className="justify-content-center">
          <Col lg="12" xl="6">
            <Over>
              <Box mb={4} className="text-center">

                <Title color="light">STILL <Span color="#00afc9">UNSURE?</Span></Title>
                <Text color="light">Why not book a consultation so we can assess you and answer your questions in
                  person</Text>
              </Box>
              <div onClick={() => setIsModalOpen(true)}>
                <button type="button" className="fullbtn2">Book an Apppointment</button>
              </div>
            </Over>
          </Col>
        </Row>

      </Container>

    </SectionNew>


  </>
);

export default NonCTA;
